import React from 'react';
import { format } from 'date-fns';
import RouterLinkOrNot from '../../Shared/RouterLinkOrNot';

const ExternalEvent = ({ href, image, title, date, isFree }) => {
  const d = new Date(Date.parse(date));
  return (
    <RouterLinkOrNot
      href={href}
      target="_blank"
      className="event-item event-item--external"
    >
      <img src={image} alt="event" />
      <div className="event-item__content">
        <h3 className="event-item__title">{title}</h3>
        {date && (
          <div className="event-item__date">
            {date &&
              format(
                new Date(d.getTime() - d.getTimezoneOffset() * 60 * 1000),
                'EEE, MMM d . h:mm aa'
              )}
          </div>
        )}
        {isFree && <div className="event-item__free">Free</div>}
      </div>
    </RouterLinkOrNot>
  );
};

export default ExternalEvent;
