import React from 'react';
import { withRouter } from 'react-router-dom';
import ExternalEvent from './ExternalEvent';
import CalendarEvent from './CalendarEvent';

const UpcomingEvents = ({ events }) => {
  const sortedEvents = events.sort((x, y) =>
    x.attributes.featured === y.attributes.featured
      ? 0
      : x.attributes.featured
      ? -1
      : 1
  );

  return (
    <>
      <h3 className="mb-20 text-uppercase">Upcoming Events</h3>
      {sortedEvents.map(
        ({
          attributes: {
            image,
            title,
            thumbnail,
            thumbnail_color,
            custom_link,
            event_description,
            event,
            start_date,
            active,
          },
          layout,
          key,
        }) => (
          <div key={key} className="mb-20">
            {layout === 'ExternalEvent' && active && (
              <ExternalEvent
                image={image}
                title={title}
                href={custom_link}
                date={start_date}
                isFree
              />
            )}
            {layout === 'CalendarEvent' && active && (
              <CalendarEvent
                image={thumbnail}
                title={event.title}
                href={`/event/${event.slug}`}
                bgColor={thumbnail_color}
                description={event_description || event.description}
                date={event.start_date}
              />
            )}
          </div>
        )
      )}
    </>
  );
};

export default withRouter(UpcomingEvents);
